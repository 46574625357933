// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import auth from "./authentication";
import lastUploadedFile from "./lastUploadedFile";
import searchSlice from "./searchSlice";
import emailcompaign from './emailCampaign'

const rootReducer = {
  auth,
  navbar,
  layout,
  lastUploadedFile,
  searchSlice,
  emailcompaign,
};

export default rootReducer;
