// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** ThemeConfig Import
import themeConfig from '@configs/themeConfig'

export const searchSlice = createSlice({
  name: 'search',
  initialState: {
    searchItem:''
  },
  reducers: {
    handleSearchItem: (state, action) => {
      state.searchItem = action.payload
    }
  }
})

export const { handleSearchItem } = searchSlice.actions

export default searchSlice.reducer
