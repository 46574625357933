// ** Redux Imports
import rootReducer from "./rootReducer";
import { applyMiddleware, configureStore, compose } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import createDebounce from "redux-debounced";
import logger from "redux-logger";
import thunk from "redux-thunk";
const middlewares = [thunk, createDebounce(), logger];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
      ...middlewares,
    });
  },
});
const persistor = persistStore(store);
export { store, persistor };
