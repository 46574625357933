// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** ThemeConfig Import
import themeConfig from '@configs/themeConfig'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from "redux-persist/lib/storage";
export const emailCampaignSlice = createSlice({
  name: 'emailcampaign',

  initialState: {
    uploadedFiles:[],
    emailCampaignDetail:{},
    separateAttachmentDetail:{}
  },

  reducers: {
    setUploadedFiles: (state, action) => {
      state.uploadedFiles = action.payload
    },
    setEmailCampaignDetails: (state,action) =>{
      state.emailCampaignDetail = action.payload
    },
    setSeparateAttachmentDetail:(state,action)=>{
      state.separateAttachmentDetail = action.payload
    }
  }

})


const persistConfig = {
  key: "emailcampaign",
  storage,
};
export const { setUploadedFiles,setEmailCampaignDetails,setSeparateAttachmentDetail } = emailCampaignSlice.actions

export default persistReducer(persistConfig, emailCampaignSlice.reducer);

// export default emailCampaignSlice.reducer
