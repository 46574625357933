// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosAuth } from "../utility/axios";
import { callApi } from "../utility/refreshTokenApiCall";
const initialState = {
  lastUploadedFilesData: {},
};

export const lastUploadedFile = createAsyncThunk(
  "latestUploadedFile",
  async () => {
    const res = await callApi({
      requestFunction: () => axiosAuth.get("admin/last-updated-all-record"),
      showToastOnSuccess: false,
      showPopUpOnSuccess: false,
      successCode: 200,
    });
    if (res.error == false) {
      return res;
    }
  }
);

export const lastUploadedFileSlice = createSlice({
  name: "latestUploadedFile",
  initialState: initialState,
  reducers: {
    handleLatestUploadedFile: (state) => {
      state.lastUploadedFilesData = {};
    },
  },
  extraReducers: {
    [lastUploadedFile.pending]: (state, action) => {
      state.lastUploadedFilesData = "...loading";
    },
    [lastUploadedFile.fulfilled]: (state, action) => {
      state.lastUploadedFilesData = action?.payload?.data;
    },
    [lastUploadedFile.rejected]: (state, action) => {
      state.lastUploadedFilesData = {};
    },
  },
});
export const { handleLatestUploadedFile } = lastUploadedFileSlice.actions;
export default lastUploadedFileSlice.reducer;

// export const lastUploadedFileSlice = createSlice({
//   name: "latestUploadedFile",
//   initialState: initialState,
//   reducers: {
//     handleLatestUploadedFile: (state) => {
//       state.lastUploadedFilesData = {};
//     },
//   },
//   extraReducers: {
//     [lastUploadedFile.pending]: (state, action) => {
//       state.buyBackLastUpdate = "...loading";
//       state.formatedDateIPOAllotment = "...loading";
//       state.formatedDateKycPendency = "...loading";
//       state.formatedDateReactivation = "...loading";
//       state.formatedDateSample = "...loading";
//       state.userRegistrationLastUpdate = "...loading";
//     },
//     [lastUploadedFile.fulfilled]: (state, action) => {
//       console.log("action last uploaded fullfilled", action);
//       state.buyBackLastUpdate = action?.payload?.data?.buyBackLastUpdate;
//       state.formatedDateIPOAllotment =
//         action?.payload?.data?.formatedDateIPOAllotment;
//       state.formatedDateKycPendenc =
//         action?.payload?.data?.formatedDateKycPendency;
//       state.formatedDateReactivation =
//         action?.payload?.data?.formatedDateReactivation;
//       state.formatedDateSample = action?.payload?.data?.formatedDateSample;
//       state.userRegistrationLastUpdate =
//         action?.payload?.data?.userRegistrationLastUpdate;
//     },
//     [lastUploadedFile.rejected]: (state, action) => {
//       console.log("action last uploaded rejected", action);
//       console.log("action last uploaded fullfilled", action);
//       state.buyBackLastUpdate = action?.payload?.data?.buyBackLastUpdate;
//       state.formatedDateIPOAllotment =
//         action?.payload?.data?.formatedDateIPOAllotment;
//       state.formatedDateKycPendency =
//         action?.payload?.data?.formatedDateKycPendency;
//       state.formatedDateReactivation =
//         action?.payload?.data?.formatedDateReactivation;
//       state.formatedDateSample = action?.payload?.data?.formatedDateSample;
//       state.userRegistrationLastUpdate =
//         action?.payload?.data?.userRegistrationLastUpdate;
//     },
//   },
// });
// export const { handleLatestUploadedFile } = lastUploadedFileSlice.actions;
// export default lastUploadedFileSlice.reducer;

// // ** Redux Imports
// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { axiosAuth } from "../utility/axios";
// import { callApi } from "../utility/refreshTokenApiCall";
// const initialState = {
//   buyBackLastUpdate: "",
//   formatedDateIPOAllotment: "",
//   formatedDateKycPendency: "",
//   formatedDateReactivation: "",
//   formatedDateSample: "",
//   userRegistrationLastUpdate: "",
// };

// export const lastUploadedFile = createAsyncThunk(
//   "latestUploadedFile",
//   async () => {
//     console.log("hell");
//     const res = await callApi({
//       requestFunction: () => axiosAuth.get("admin/last-updated-all-record"),
//       showToastOnSuccess: false,
//       showPopUpOnSuccess: false,
//       successCode: 200,
//     });
//     console.log("res cehcking", res);
//     if (res.error == false) {
//       return res;
//     }
//   }
// );

// export const lastUploadedFileSlice = createSlice({
//   name: "latestUploadedFile",
//   initialState: initialState,
//   reducers: {
//     handleLatestUploadedFile: (state) => {
//       state.lastUploadedFilesData = {};
//     },
//   },
//   extraReducers: {
//     [lastUploadedFile.pending]: (state, action) => {
//       state.buyBackLastUpdate = "...loading";
//       state.formatedDateIPOAllotment = "...loading";
//       state.formatedDateKycPendency = "...loading";
//       state.formatedDateReactivation = "...loading";
//       state.formatedDateSample = "...loading";
//       state.userRegistrationLastUpdate = "...loading";
//     },
//     [lastUploadedFile.fulfilled]: (state, action) => {
//       console.log("action last uploaded fullfilled", action);
//       state.buyBackLastUpdate = action?.payload?.data?.buyBackLastUpdate;
//       state.formatedDateIPOAllotment =
//         action?.payload?.data?.formatedDateIPOAllotment;
//       state.formatedDateKycPendenc =
//         action?.payload?.data?.formatedDateKycPendency;
//       state.formatedDateReactivation =
//         action?.payload?.data?.formatedDateReactivation;
//       state.formatedDateSample = action?.payload?.data?.formatedDateSample;
//       state.userRegistrationLastUpdate =
//         action?.payload?.data?.userRegistrationLastUpdate;
//     },
//     [lastUploadedFile.rejected]: (state, action) => {
//       console.log("action last uploaded rejected", action);
//       console.log("action last uploaded fullfilled", action);
//       state.buyBackLastUpdate = action?.payload?.data?.buyBackLastUpdate;
//       state.formatedDateIPOAllotment =
//         action?.payload?.data?.formatedDateIPOAllotment;
//       state.formatedDateKycPendency =
//         action?.payload?.data?.formatedDateKycPendency;
//       state.formatedDateReactivation =
//         action?.payload?.data?.formatedDateReactivation;
//       state.formatedDateSample = action?.payload?.data?.formatedDateSample;
//       state.userRegistrationLastUpdate =
//         action?.payload?.data?.userRegistrationLastUpdate;
//     },
//   },
// });
// export const { handleLatestUploadedFile } = lastUploadedFileSlice.actions;
// export default lastUploadedFileSlice.reducer;
