import axios from "axios";

// import useJwt from "@src/@core/auth/jwt/useJwt";
// import { toast, Slide } from "react-toastify";
import React, { Fragment } from "react";
// import Avatar from "@components/avatar";
// import { Coffee } from "react-feather";

import { ToastBody, Toast, ToastHeader } from "reactstrap";
// import { store } from "../redux/store";
import browserId from "./browserId";
export const ToastContent = ({ title, message }) => (
  <Fragment>
    <Toast>
      <ToastHeader icon="primary">{title}</ToastHeader>
      <ToastBody>{message}</ToastBody>
    </Toast>
  </Fragment>
);

axios.defaults.timeout = 60000;
axios.defaults.headers.common["device-type"] = "WEB";
axios.defaults.headers.common["device-name"] = "9Star Admin Panel";
axios.defaults.headers.common["device-token"] = JSON.parse(
  localStorage.getItem("accessToken")
);
axios.defaults.headers.common["device-id"] = `${browserId()}`;
axios.defaults.headers.common["app-version"] = "1.0";
axios.defaults.headers.common["device-os-version"] = "";
axios.defaults.headers.common["environment"] = "DEVELOPMENT";
axios.defaults.headers.common["locale-code"] = "en";
axios.defaults.headers.common["tm"] = "";
axios.defaults.headers.common["app-signature"] = "";

const handleSuccess = (response) => {
  switch (response.status) {
    case 200:
      break;
    case 201:
      break;
    default:
      break;
  }
};

const handleClientError = (response) => {
  switch (response.status) {
    case 400:
      break;
    case 401:
      break;
    default:
      break;
  }
};

const getRange = (code) => {
  if (code >= 200 && code < 300) {
    return "SUCCESS_RANGE";
  }
  if (code >= 400 && code < 500) {
    return "CLIENT_ERROR_RANGE";
  }
  if (code >= 500 && code < 600) {
    return "SERVER_ERROR_RANGE";
  }
  return "UNKNOWN";
};

const handleResponse = (response) => {
  const range = getRange(response.status);
  //  console.log(range, "range");
  switch (range) {
    case "SUCCESS_RANGE":
      handleSuccess(response);
      break;
    case "CLIENT_ERROR_RANGE":
      handleClientError(response);
      break;
    case "SERVER_ERROR_RANGE":
      break;
    case "UNKNOWN":
      break;
    default:
      break;
  }
};

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  responseType: "json",
  headers: { "Access-Control-Allow-Origin": "*" },
});

export const axiosAuth = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  responseType: "json",
  headers: { "Access-Control-Allow-Origin": "*" },
});

axiosInstance.interceptors.response.use(
  (response) => {
    handleResponse(response);
    return Promise.resolve(response);
  },
  (error) => {
    console.log(error.response, "error.response");
    handleResponse(error.response);
    return Promise.reject(error);
  }
);

axiosAuth.interceptors.response.use(
  (response) => {
    handleResponse(response);
    // console.log(response, "axiosAuth.interceptors.response.use.response");
    return Promise.resolve(response);
  },
  (error) => {
    console.log(error.response, "error.response");
    handleResponse(error.response);
    console.log(error, "axiosAuth.interceptors.response.use.error");
    return Promise.reject(error);
  }
);
axiosAuth.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${JSON.parse(
    localStorage.getItem("accessToken")
  )}`;

  return config;
});
