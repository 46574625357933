// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../utility/axios";
// ** UseJWT import to get config
import useJwt from "@src/auth/jwt/useJwt";
import { toast, Slide } from "react-toastify";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
const toastSlide = {
  transition: Slide,
  hideProgressBar: true,
  autoClose: 1500,
  toastId: "authentication",
};
// console.log("config", config);
const initialState = {
  userData: null,
  is_authenticated: false,
  isLogged: false,
  data: null,
  check: true,
  username: "",
  userRole: "",
  accessToken: "",
  refreshToken: "",
  buildVersion: 1,
  isLoading: false,
};

export const login = createAsyncThunk("authentication", async (data) => {
  try {
    const res = await axiosInstance.post("admin/sign-in", data);
    console.log("res login", res);
    // toast.configure();
    toast.success(res?.data?.message || "Sign in successfully", {
      ...toastSlide,
    });
    return res.data.data;
  } catch (error) {
    const message = error?.response?.data?.message ?? "Something went wrong";
    toast.error(message, { ...toastSlide });
    throw error.response;
  }
});

// ** Handle User Logout

export const authSlice = createSlice({
  name: "authentication",
  initialState: initialState,
  reducers: {
    handleLogin: (state, action) => {
      console.log("handleLogin handleLogin action get called", action);
      state.username = "Admin";
      state.userRole = "admin";
      state.userData = action?.payload?.user;
      state.data = action?.payload?.user;
      state.accessToken = action.payload?.tokens?.accessToken;
      state.refreshToken = action.payload?.tokens?.refreshToken;
      storage.setItem("userData", JSON.stringify(action.payload?.user));
      storage.setItem(
        "accessToken",
        JSON.stringify(action.payload?.tokens?.accessToken)
      );
      state.isLogged = true;
      state.is_authenticated = true;
    },
    handleLogout: (state) => {
      console.log("handle logout state", state);
      state.isLoading = false;
      state.userData = null;
      state.data = null;
      state.isLogged = false;
      state.is_authenticated = false;
      state.accessToken = "";
      state.refreshToken = "";
      storage.removeItem("userData");
      storage.removeItem("accessToken");
    },
  },
  extraReducers: {
    [login.pending]: (state, action) => {
      state.isLoading = true;
    },
    [login.fulfilled]: (state, action) => {
      state.username = "Admin";
      state.userRole = "admin";
      state.userData = action?.payload?.user;
      state.data = action?.payload?.user;
      state.accessToken = action.payload?.tokens?.accessToken;
      state.refreshToken = action.payload?.tokens?.refreshToken;
      storage.setItem("userData", JSON.stringify(action.payload?.user));
      storage.setItem(
        "accessToken",
        JSON.stringify(action.payload?.tokens?.accessToken)
      );
      state.isLogged = true;
      state.is_authenticated = true;
    },
    [login.rejected]: (state, action) => {
      state.isLogged = false;
      state.isLoading = false;
    },
  },
});
const persistConfig = {
  key: "auth",
  storage,
};

export const { handleLogin, handleLogout } = authSlice.actions;

export default persistReducer(persistConfig, authSlice.reducer);
